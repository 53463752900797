import Nav from "../DashboardDoctor/Nav";
import "bootstrap/js/dist/dropdown";
import axios from "axios";
import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap
import React, { useEffect, useState } from "react";

function HomeListDoctor({ Toggle }) {
  const token = localStorage.getItem("token");
  
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null); // State for selected detection
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  

  const handleShowDeleteModal = (doctor) => {
    setSelectedDoctor(doctor);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedDoctor(null);
  };

  const getDoctors = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let response = await axios.get(
        `https://miocardial.humicprototyping.com/api/doctors`
      );
      const data = response.data.data;
      setDoctors(data);

    } catch (e) {
      console.log(e.message);
    }
  };

  const handleDeleteDoctor = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.delete(
        `https://miocardial.humicprototyping.com/api/delete-patient/${selectedDoctor.id}`
      );
      setDoctors(
        doctors.filter((doctor) => doctor.id !== selectedDoctor.id)
      );
      handleCloseDeleteModal();
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getDoctors();
  }, []);

  return (
    <div className="px-3">
      <Nav Toggle={Toggle} />

      <table class="table caption-top bg-white rounded mt-2">
        <caption className="text-white fs-4">All Doctors</caption>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Gender</th>
            <th scope="col">Specialization</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(doctors) && doctors.length > 0 ? (
            doctors.map((doctor) => {
              return (
                <tr>
                  <td>{doctor.id}</td>
                  <td>{doctor.name}</td>
                  <td>{doctor.gender}</td>
                  <td>{doctor.specialization}</td>
                  <td>{doctor.email}</td>
                  <td>{doctor.phone}</td>
                  <td>
                    <i
                      class="fa-solid fa-trash"
                      onClick={() => handleShowDeleteModal(doctor)}
                    ></i>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">Loading Doctors...</td>
            </tr>
          )}
        </tbody>
      </table>

      

      {/* Modal for confirming deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Doctor?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleDeleteDoctor}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomeListDoctor;
