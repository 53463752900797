import RegisterForm from '../components/registerpage/RegisterForm'
import '../style/registerpage.css';

function RegisterPage(){
    return (
        <div className='bodyregister'>
            <RegisterForm/>
        </div>
    )

}

export default RegisterPage;