import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import "bootstrap/js/dist/dropdown";
import axios from "axios";
import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap

function Home({ Toggle }) {
  const token = localStorage.getItem("token");

  const [detections, setDetections] = useState([]);
  const [countYes, setCountYes] = useState(0);
  const [countNull, setCountNull] = useState(0);
  const [countMI, setCountMI] = useState(0);
  const [countNormal, setCountNormal] = useState(0);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedDetection, setSelectedDetection] = useState(null); // State for selected detection

  const [showPatientModal, setShowPatientModal] = useState(false); // State for patient modal visibility
  const [selectedPatient, setSelectedPatient] = useState(null); // State for selected patient

  const [editNotes, setEditNotes] = useState(""); // State untuk menyimpan input notes yang diubah
  const [isVerified, setIsVerified] = useState(false); // State untuk menyimpan status verified

  const getDetections = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let response = await axios.get(
        "https://miocardial.humicprototyping.com/api/detections"
      );
      const data = response.data.data;
      setDetections(data);

      // Calculate counts
      const yesCount = data.filter((d) => d.verified === "yes").length;
      const nullCount = data.filter((d) => d.verified === null).length;
      const miCount = data.filter((d) => d.condition === "mi").length;
      const normalCount = data.filter((d) => d.condition === "normal").length;

      setCountYes(yesCount);
      setCountNull(nullCount);
      setCountMI(miCount);
      setCountNormal(normalCount);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleUpdateDetection = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      await axios.patch(
        `https://miocardial.humicprototyping.com/api/update-detection/${selectedDetection.patient_id}/${selectedDetection.id}`,
        {
          verified: isVerified ? "yes" : null,
          notes: editNotes,
        }
      );

      // Memperbarui tampilan dengan data yang diupdate
      setDetections((prevDetections) =>
        prevDetections.map((detection) =>
          detection.id === selectedDetection.id
            ? {
                ...detection,
                verified: isVerified ? "yes" : null,
                notes: editNotes,
              }
            : detection
        )
      );

      setShowModal(false); // Tutup modal setelah update
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleShowModal = (detection) => {
    setSelectedDetection(detection);
    setEditNotes(detection.notes || ""); // Mengisi dengan notes yang ada atau string kosong
    setIsVerified(detection.verified === "yes"); // Mengisi checkbox berdasarkan status "verified"
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDetection(null);
  };

  const handleShowPatientModal = async (patientId) => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let response = await axios.get(
        `https://miocardial.humicprototyping.com/api/patients/${patientId}`
      );
      setSelectedPatient(response.data.data);
      setShowPatientModal(true);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleClosePatientModal = () => {
    setShowPatientModal(false);
    setSelectedPatient(null);
  };

  useEffect(() => {
    getDetections();
  }, []);

  return (
    <div className="px-3">
      <Nav Toggle={Toggle} />
      <div className="container-fluid">
        <div className="row g-3 my-2">
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countYes}</h3>
                <h3 className="fs-5">Verified</h3>
              </div>
              <i className=" fa-solid fa-check p-3 fs-1"></i>
            </div>
          </div>
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countNull}</h3>
                <h3 className="fs-5">Not Verified</h3>
              </div>
              <i className=" fa-regular fa-circle-xmark p-3 fs-1"></i>
            </div>
          </div>
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countMI}</h3>
                <h3 className="fs-5">Mi</h3>
              </div>
              <i className=" fa-solid fa-triangle-exclamation p-3 fs-1"></i>
            </div>
          </div>
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countNormal}</h3>
                <h3 className="fs-5">Normal</h3>
              </div>
              <i className=" fa-regular fa-heart p-3 fs-1"></i>
            </div>
          </div>
        </div>
      </div>

      <table class="table caption-top bg-white rounded mt-2">
        <caption className="text-white fs-4">Latest Diagnoses</caption>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Patient ID</th>
            <th scope="col">Condition</th>
            <th scope="col">Verified</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(detections) && detections.length > 0 ? (
            detections.map((detection) => {
              return (
                <tr>
                  <td>{detection.id}</td>
                  <td>{detection.patient_id}</td>
                  <td>{detection.condition}</td>
                  <td>
                    {detection.verified === "yes" ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-regular fa-circle-xmark"></i>
                    )}
                  </td>
                  <td>
                    <i
                      class="fa-solid fa-pen-to-square px-3"
                      onClick={() => handleShowModal(detection)}
                    ></i>
                    <i
                      class="fa-solid fa-user"
                      onClick={() =>
                        handleShowPatientModal(detection.patient_id)
                      }
                    ></i>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">Loading Detections..</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for showing and editing detection details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detection Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDetection && (
            <div>
              <p>
                <strong>Heartwave:</strong>
              </p>
              {selectedDetection.heartwave ? (
                <audio controls>
                  <source
                    src={`https://miocardial.humicprototyping.com/myocardial_baru/storage/app/public/heartwaves/${selectedDetection.heartwave
                      .split("/")
                      .pop()}`}
                    type="audio/wav"
                  />
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <p>No heartwave file available.</p>
              )}
              <p>
                <strong>Patient ID:</strong> {selectedDetection.patient_id}
              </p>
              <p>
                <strong>Condition:</strong> {selectedDetection.condition}
              </p>
              <p>
                <strong>Verified:</strong>{" "}
                {selectedDetection.verified || "Not Verified"}
              </p>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="verifyCheck"
                  checked={isVerified}
                  onChange={(e) => setIsVerified(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="verifyCheck">
                  Mark as Verified
                </label>
              </div>
              <div className="mt-3">
                <label htmlFor="editNotes" className="form-label">
                  Notes
                </label>
                <textarea
                  id="editNotes"
                  className="form-control"
                  value={editNotes}
                  onChange={(e) => setEditNotes(e.target.value)}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleUpdateDetection}>
            Update
          </button>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal for showing patient details */}
      <Modal show={showPatientModal} onHide={handleClosePatientModal}>
        <Modal.Header closeButton>
          <Modal.Title>Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPatient ? (
            <div>
              <p>
                <strong>ID:</strong> {selectedPatient.id}
              </p>
              <p>
                <strong>Name:</strong> {selectedPatient.name}
              </p>
              <p>
                <strong>Gender:</strong> {selectedPatient.gender}
              </p>
              <p>
                <strong>Email:</strong> {selectedPatient.email}
              </p>
              <p>
                <strong>Phone:</strong> {selectedPatient.phone}
              </p>
              {/* Add more patient details as needed */}
            </div>
          ) : (
            <p>Loading patient details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleClosePatientModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Home;
