import LoginForm from '../components/loginpage/LoginForm'
import '../style/loginpage.css';

function LoginPage(){
    return (
        <div className='bodylogin'>
            <LoginForm/>
        </div>
    )

}

export default LoginPage;