import Nav from "../DashboardDoctor/Nav";
import "bootstrap/js/dist/dropdown";
import axios from "axios";
import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap
import React, { useEffect, useState } from "react";

function Homepatient({ Toggle }) {
  const token = localStorage.getItem("token");
  const patientId = localStorage.getItem("patientId"); // Retrieve patient ID from local storage
  const [detections, setDetections] = useState([]);
  const [countYes, setCountYes] = useState(0);
  const [countNull, setCountNull] = useState(0);
  const [countMI, setCountMI] = useState(0);
  const [countNormal, setCountNormal] = useState(0);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedDetection, setSelectedDetection] = useState(null); // State for selected detection
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getDetections = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      let response = await axios.get(
        `https://miocardial.humicprototyping.com/api/detections/${patientId}`
      );
      const data = response.data.data;
      setDetections(data);

      // Calculate counts
      const yesCount = data.filter((d) => d.verified === "yes").length;
      const nullCount = data.filter((d) => d.verified === null).length;
      const miCount = data.filter((d) => d.condition === "mi").length;
      const normalCount = data.filter((d) => d.condition === "normal").length;

      setCountYes(yesCount);
      setCountNull(nullCount);
      setCountMI(miCount);
      setCountNormal(normalCount);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleShowModal = (detection) => {
    setSelectedDetection(detection);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDetection(null);
  };

  const handleShowDeleteModal = (detection) => {
    setSelectedDetection(detection);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedDetection(null);
  };

  const handleDeleteDetection = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.delete(
        `https://miocardial.humicprototyping.com/api/delete-detection/${patientId}/${selectedDetection.id}`
      );
      setDetections(
        detections.filter((detection) => detection.id !== selectedDetection.id)
      );
      handleCloseDeleteModal();
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getDetections();
  }, []);

  return (
    <div className="px-3">
      <Nav Toggle={Toggle} />
      <div className="container-fluid">
        <div className="row g-3 my-2">
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countYes}</h3>
                <h3 className="fs-5">Verified</h3>
              </div>
              <i className=" fa-solid fa-check p-3 fs-1"></i>
            </div>
          </div>
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countNull}</h3>
                <h3 className="fs-5">Not Verified</h3>
              </div>
              <i className=" fa-regular fa-circle-xmark p-3 fs-1"></i>
            </div>
          </div>
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countMI}</h3>
                <h3 className="fs-5">Mi</h3>
              </div>
              <i className=" fa-solid fa-triangle-exclamation p-3 fs-1"></i>
            </div>
          </div>
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{countNormal}</h3>
                <h3 className="fs-5">Normal</h3>
              </div>
              <i className=" fa-regular fa-heart p-3 fs-1"></i>
            </div>
          </div>
        </div>
      </div>

      <table class="table caption-top bg-white rounded mt-2">
        <caption className="text-white fs-4">My Diagnoses</caption>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Patient ID</th>
            <th scope="col">Condition</th>
            <th scope="col">Verified</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(detections) && detections.length > 0 ? (
            detections.map((detection) => {
              return (
                <tr>
                  <td>{detection.id}</td>
                  <td>{detection.patient_id}</td>
                  <td>{detection.condition}</td>
                  <td>
                    {detection.verified === "yes" ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-regular fa-circle-xmark"></i>
                    )}
                  </td>
                  <td>
                    <i
                      class="fa-solid fa-pen-to-square px-3"
                      onClick={() => handleShowModal(detection)}
                    ></i>
                    <i
                      class="fa-solid fa-trash"
                      onClick={() => handleShowDeleteModal(detection)}
                    ></i>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">Loading Detections..</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for showing detection details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detection Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDetection && (
            <div>
              <p>
                <strong>Heartwave:</strong>
              </p>
              {selectedDetection.heartwave ? (
                <audio controls>
                <source
                  src={`https://miocardial.humicprototyping.com/myocardial_baru/storage/app/public/heartwaves/${selectedDetection.heartwave.split('/').pop()}`}
                  type="audio/wav"
                />
                Your browser does not support the audio element.
              </audio>
              ) : (
                <p>No heartwave file available.</p>
              )}
              <p>
                <strong>Detection ID:</strong> {selectedDetection.id}
              </p>
              <p>
                <strong>Condition:</strong> {selectedDetection.condition}
              </p>
              <p>
                <strong>Verified:</strong>{" "}
                {selectedDetection.verified || "Not Verified"}
              </p>
              <p>
                <strong>Notes:</strong> {selectedDetection.notes || "No Notes"}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirming deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this detection?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleDeleteDetection}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Homepatient;
